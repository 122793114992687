import { ItemPhotoDto, ItemThumbnailSize } from '@marketplace-web/domain/item-data'
import { BumpableItemModel, BumpableItemPreCheckoutModel, ProductItemModel } from 'types/models'

export const getBumpableItemThumbnailFromDto = (
  photos: Array<ItemPhotoDto> | undefined,
  size = ItemThumbnailSize.Large,
) => {
  return (
    photos?.find(photo => photo.is_main)?.thumbnails?.find(thumb => thumb.type === size)?.url ||
    null
  )
}

export const getBumpableItemDataFromModel = (
  item: ProductItemModel | BumpableItemModel,
): BumpableItemPreCheckoutModel => {
  return {
    id: item.id,
    title: item.title,
    thumbnail: item.thumbnailUrl,
  }
}
